* {
  box-sizing: border-box;
}

.Header {
  display: flex;
  background-color: rgb(9, 9, 9);
  align-items: center;
  align-content: center;
  color: #ffffff;
}

.Nav {
  list-style: none;
  display: flex;
  font-size: 1.2rem;
  float: right;
  margin-left: auto;
}

.Projects-btn {
  margin-right: 20px;
  color: #000000;
  background-color: #ffffff;
  padding: 5px;
  font-weight: 900;
  border-radius: 5px;
  cursor: pointer;
}

.Contact-btn {
  margin-right: 20px;
  padding: 5px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}

.mm {
  font-size: 5rem;
  text-align: center;
  animation: words;
  animation-duration: 1s;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}

.bg {
  background: url("./Images/bg_dark_tech.jpeg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
}
.Title {
  font-size: 1.5rem;
}

.logo {
  background-color: #ffffff;
  color: #000;
  padding: 2px 5px;
  margin: 0 5px 0 20px;
  border-radius: 10px;
  font-weight: 900;
}

a {
  text-decoration: none;
}

@keyframes words {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1350px) {
  .mm {
    font-size: 2rem;
  }
  .Title {
    font-size: 1rem;
  }
  .Projects-btn,
  .Contact-btn {
    font-size: 1rem;
  }
  .Projects-btn {
    margin: 0 8px 00;
  }
}

@media (max-width: 500px) {
  .pp {
    font-size: 2rem;
  }
  .mm {
    height: 30vh;
  }
}
