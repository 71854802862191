.Pro-header {
  text-align: center;
  font-size: 2rem;
  color: #000000;
  margin-top: 200px;
  background-color: #ffffff;
}

.Project {
  width: 500px;
  height: 750px;
  &s-container {
    display: flex;
    max-width: 2000px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    align-items: center;
  }
  &-hacker {
    background: url("./Images/hacker.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: #000000 2px solid;
    background-position: center;
    z-index: 2;
    position: relative;
    background-size: cover;
  }
  &-youtube {
    background: url("./Images/youtube.png");
    position: relative;
    z-index: 2;
    background-position: 0%;
    background-size: cover;
  }
  &-xplorer {
    background: url("./Images/xplore.png");
    border: #000000 2px solid;
    position: relative;
    background-size: cover;
  }
}

.bg-color {
  position: absolute;
  height: 53vh;
  background: rgba(0, 0, 0, 0.077);
  width: 100vw;
  z-index: 0;
}

.Proj-title {
  margin-left: 70px;
  animation: effect;
  animation-duration: 1s;
  font-size: 2.5rem;
  font-family: "Kaisei Tokumin", serif;
}

.fade {
  background: rgb(3, 1, 1);
  background: linear-gradient(
    180deg,
    rgba(3, 1, 1, 1) 12%,
    rgba(3, 1, 1, 0.64150272326523) 69%,
    rgba(0, 0, 0, 0.35498586933264575) 91%
  );
  width: 500px;
  height: 750px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.btn {
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 900;
  border-radius: 2px;
  &s {
    display: flex;
    margin-left: 70px;
    animation: visible-btn;
    animation-duration: 1.5s;
  }
}

.demo {
  width: 100px;
}

.Pro {
  margin: 20px;
}

.pro {
  &-btns {
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    width: 200px;
    margin: 0 auto;
    // font-size: 3rem;
    padding: 0;
    animation: effect;
    animation-duration: 1.5s;
  }
  &-name {
    font-size: 2.5rem;
    color: #ffffff;
  }
}

.git,
.live {
  color: #000000;
  text-decoration: none;
  background-color: #ffffff;
  border-radius: 5px;
}

@keyframes effect {
  0% {
    margin-top: 0px;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes visible-btn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1625px) {
  .Project,
  .fade {
    width: 430px;
    height: 650px;
  }
}

@media (max-width: 500px) {
  .bg-color {
    height: 100vh;
  }
  .Project,
  .fade {
    width: 320px;
    height: 520px;
  }

  .pro {
    &-btns {
      font-size: 1rem;
    }
    &-name {
      font-size: 1.6rem;
    }
  }
}
