.about-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.about-description {
  font-size: 1.5rem;
  max-width: 1000px;
  text-align: center;
  margin: 100px 0 0 0;
}

.stock-pics {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.stock-div {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.js,
.html,
.css {
  padding: 50px;
}

.cpp {
  padding: 40px;
}

.java {
  padding: 20px;
}

.rjs {
  padding: 20px;
}

.aws {
  width: 200px;
}

@media (max-width: 1350px) {
  .stock-div {
    margin-top: -10px;
  }
  .stock-pics {
    width: 200px;
    height: 200px;
  }
  .about-description {
    padding: 0;
    margin: 60px 15px 0 15px;
    font-size: 1.2rem;
    width: auto;
    height: 30vh;
  }
}

@media (max-width: 500px) {
  .about-description {
    height: 45vh;
    font-size: 1.1rem;
  }

  .stock-pics {
    width: 70px;
    height: 70px;
    margin: 50px;
  }
  .js,
  .html,
  .css {
    padding: 0px;
  }

  .cpp {
    padding: 0;
  }

  .java {
    padding: 0;
  }

  .rjs {
    padding: 0;
  }

  .aws {
    width: 100px;
  }
}
