.contact-container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(34, 34, 34);
  color: #ffffff;
  margin-top: 200px;
}

.linkedIn {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
}

.contact_img {
  width: 45px;
}

.twitter-logo {
  margin-left: 20px;
}
